import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import ConfirmDialog from "./confirmDialog";

import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoIcon from "@material-ui/icons/Info";
import CopyDialog from "./copyDialog";

import { ConvertUTCToHumanReadableDate } from "../../services/TimeConvertor";
import { toasty, error } from "../../services/toastNotification";
// import logger from "../../services/logService";

const useStyles = makeStyles((theme) => ({
  alret: {
    marginBottom: "1rem",
  },
  actionIconColor: {
    color: "#212121",
    cursor: "pointer",
  },
  warningIconColor: {
    color: "#fcb952",
    cursor: "pointer",
  },
}));

let instancesCount = 0;
const PreviewRepetitions = ({
  reps,
  handleDeleteRep,
  viewSchedulerMode,
  previewRepetitionAlertObj,
}) => {
  /* ------------ 
  remove the first rep (just for the first time)
  bc the first rep is alredy provided in start/end time component. 

  the concept of deleteing a repetition only belongs to schedule 2.
  so it is just implemented for viewSchedulerMode = sche2
  ---------------*/
  const [repetition, setRepetition] = useState(reps);

  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    setFirstRender(instancesCount === 0);
    instancesCount++;
    return () => {
      instancesCount--;
    };
  }, [reps]);

  if (firstRender) {
    let localRep = [...reps];
    localRep.shift();
    setRepetition(localRep);
    setFirstRender(false);
  }

  // Schedule2 Delete Drama :)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [cachedDataIndex, setCachedDataIndex] = useState();
  const onDeleteSche2Clicked = (dataIndex) => {
    // Check if event has been started
    if (repetition[dataIndex]["customFields"]["hasStarted"]) {
      error("Repetition can not be deleted!");
    } else {
      setOpenDeleteDialog(true);
      setCachedDataIndex(dataIndex);
    }
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(false);
    handleDeleteRep(cachedDataIndex);
    toasty("Repetition was successfully deleted!");
  };

  // logger.logInfo("repetition:", repetition);

  const [cachedUuid, setCachedUuid] = useState("");
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const onOpenCopyDialog = (value) => {
    setOpenCopyDialog(true);
    setCachedUuid(value);
  };
  const handleCopyeDialogClose = () => {
    setOpenCopyDialog(false);
  };
  // const handleCopyDialogConfirm = () => {}

  const classes = useStyles();
  const columns = [
    {
      label: "PreActive Time",
      name: "startPreActiveTime",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const localValue = repetition[dataIndex]["startPreActiveTime"];
          return localValue === 0
            ? 0
            : ConvertUTCToHumanReadableDate(localValue);
        },
      },
    },
    {
      label: "Start Time",
      name: "startTime",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return ConvertUTCToHumanReadableDate(
            repetition[dataIndex]["startTime"]
          );
        },
      },
    },
    {
      label: "End Time",
      name: "endTime",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return ConvertUTCToHumanReadableDate(
            repetition[dataIndex]["endTime"]
          );
        },
      },
    },
    {
      label: "End Join Time",
      name: "endJoinTime",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return ConvertUTCToHumanReadableDate(
            repetition[dataIndex]["endJoinTime"]
          );
        },
      },
    },
    {
      label: "UUID",
      name: "eventReputationUuId",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const value = repetition[dataIndex]["eventReputationUuId"];
          return (
            <IconButton onClick={() => onOpenCopyDialog(value)}>
              <InfoIcon
                className={
                  value === ""
                    ? classes.warningIconColor
                    : classes.actionIconColor
                }
              />
            </IconButton>
          );
        },
      },
    },
    ...(viewSchedulerMode === "schedule2"
      ? [
          {
            name: "Delete",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRenderLite: (dataIndex, rowIndex) => {
                return (
                  <IconButton onClick={() => onDeleteSche2Clicked(dataIndex)}>
                    <DeleteForeverIcon className={classes.actionIconColor} />
                  </IconButton>
                );
              },
            },
          },
        ]
      : []),
  ];

  const options = {
    filter: false,
    responsive: "vertical",
    selectableRows: "none",
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    // enableNestedDataAccess: ".",
    draggableColumns: {
      enabled: true,
    },
    resizableColumns: true,
    page: 0,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: repetition[dataIndex]?.customFields?.hasStarted
            ? repetition[dataIndex]["customFields"]["hasStarted"]
              ? "#f0f0f0"
              : "white"
            : "white",
        },
      };
    },
  };

  return (
    <>
      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteConfirm}
          itemName="repetition"
        />
      )}
      {openCopyDialog && (
        <CopyDialog
          open={openCopyDialog}
          onClose={handleCopyeDialogClose}
          // onConfirm={handleCopyDialogConfirm}
          // itemName="repetition"
          value={cachedUuid}
        />
      )}
      <Grid item xs={12} sm={12} className={classes.alret}>
        <Alert severity={previewRepetitionAlertObj.severity}>
          {previewRepetitionAlertObj.msg}
        </Alert>
      </Grid>
      <Grid item xs={12} sm={12}>
        <MUIDataTable
          title="Next event repetition times:"
          data={repetition}
          columns={columns}
          options={options}
        />
      </Grid>
    </>
  );
};

export default PreviewRepetitions;
