import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Flatpickr from "react-flatpickr";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { formatDate } from "../services/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  calendarIcon: {
    marginLeft: "-30px",
    marginTop: "10px",
  },
}));

function PreActivation({
  checked,
  onCheckboxChange,
  ceckboxLabel,
  date,
  onDateChange,
  datePickerLabel,
}) {
  const classes = useStyles();

  const checkLabelStatus = () => {
    if (checked) return datePickerLabel + " *";
    return datePickerLabel;
  };

  // const createError = () => {throw new Error("💥 CABOOM 💥");};

  return (
    <div className={classes.gridRoot}>
      {/* {createError()} */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onCheckboxChange}
                color="primary"
              />
            }
            label={ceckboxLabel}
            labelPlacement="start"
            style={{ marginLeft: "0px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <label htmlFor="pre-activation" className="date-picker-label">
            {checkLabelStatus()}
          </label>
          <div style={{ display: "flex" }}>
            <Flatpickr
              disabled={!checked}
              id="pre-activation"
              value={date}
              data-enable-time
              onChange={(e) => {
                if (e[0]) {
                  let userTime = e[0].getTime();
                  let time = formatDate(userTime);
                  onDateChange(time);
                } else {
                  onDateChange("");
                }
              }}
              className="date-picker-input"
              options={{
                disableMobile: "true",
                time_24hr: "true",
              }}
            />
            <CalendarTodayIcon
              color={!checked ? "disabled" : "action"}
              fontSize="small"
              className={classes.calendarIcon}
            />
          </div>
          {date?.length === 0 && checked && (
            <div className="error-text">Please select a valid date</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default PreActivation;
