import React from "react";
import auth from "./../../services/authService";
import { Route, Redirect } from "react-router-dom";
import Login from "./../login";
import { convertToEpochTime } from "./../../services/TimeConvertor";

let crDate = 0;

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = auth.getCurrentUser();
  return (
    <Route
      {...rest} // for any additional props
      // path={path}
      render={(props) => {
        crDate = convertToEpochTime(new Date().getTime());
        if (!user.token || crDate > parseInt(user.exp)) {
          auth.logout();
          return <Login {...props} path="/login" />;
        } else if (user.token && path === "/login") {
          return <Redirect to={{ pathname: "/" }} />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
