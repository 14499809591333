import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import PreviewRepetitions from "./common/previewRepetitions";
import { timeDiffCalculation, formatDate } from "../services/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  switch: {
    alignSelf: "center",
  },
  gridRoot: {
    flexGrow: 1,
  },
  helperText: {
    fontSize: "12px",
    color: "#b1b1b1",
  },
  inputAdornmentFont: {
    fontSize: "10px",
    color: "#b1b1b1",
  },
}));

function SchedualIntervalTime({
  onSwitchChange,
  switchChecked,
  interval_Day,
  handleIntervalChange_Day,
  sch1Interval_Hour,
  handleIntervalChange_Hour,
  sch1Interval_Minute,
  handleIntervalChange_Minute,
  handleRepeatChange,
  repeat,
  preAcDate,
  startTimeDate,
  endTimeDate,
  handleSch1NextRepValidation,
  mode,
  reps,
  viewSchedulerMode,
  Sche1RepDeletionIsValid,
  previewRepetitionAlertObj,
  hasPropsTouched,
}) {
  const classes = useStyles();
  const [nextValidDate, setNextValidDate] = useState({});
  const [isSch1NextRepValid, setIsSch1NextRepValid] = useState(true);

  const calculateNextValidDate = useCallback(async () => {
    let convertLocalTimeToMin =
      parseInt(sch1Interval_Minute) +
      parseInt(sch1Interval_Hour) * 60 +
      parseInt(interval_Day) * 24 * 60;

    let timeOffset =
      preAcDate !== 0 ? endTimeDate + (startTimeDate - preAcDate) : endTimeDate;

    let nextValidDate = timeDiffCalculation(
      formatDate(timeOffset),
      formatDate(startTimeDate)
    );
    setNextValidDate(nextValidDate);

    let convertNextValidDateToMin =
      nextValidDate.minutes +
      nextValidDate.hours * 60 +
      nextValidDate.days * 24 * 60;

    if (repeat >= 1) {
      if (convertLocalTimeToMin >= convertNextValidDateToMin) {
        setIsSch1NextRepValid(true);
        handleSch1NextRepValidation(true);
      } else {
        setIsSch1NextRepValid(false);
        handleSch1NextRepValidation(false);
      }
    } else {
      setIsSch1NextRepValid(true);
      handleSch1NextRepValidation(true);
    }
  }, [
    preAcDate,
    startTimeDate,
    endTimeDate,
    interval_Day,
    sch1Interval_Hour,
    sch1Interval_Minute,
    handleSch1NextRepValidation,
    repeat,
  ]);

  useEffect(() => {
    calculateNextValidDate();
  }, [calculateNextValidDate]);

  const calculateNextValidDateAlertSeverity = useCallback(() => {
    if (parseInt(repeat) === 0 && hasPropsTouched) {
      return "warning";
    }
    return isSch1NextRepValid ? "info" : "error";
  }, [hasPropsTouched, isSch1NextRepValid, repeat]);

  useEffect(() => {
    calculateNextValidDateAlertSeverity();
  }, [calculateNextValidDateAlertSeverity]);

  return (
    <div className={classes.gridRoot}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} className={classes.switch}>
          <FormControlLabel
            control={
              <Switch
                checked={switchChecked}
                onChange={onSwitchChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Schedule1"
            labelPlacement="start"
            style={{ marginLeft: "0px" }}
            disabled={mode === "edit" ? true : false}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <label htmlFor="intervalDay" className="date-picker-label">
            {`pick days${switchChecked ? "*" : ""}`}
          </label>
          <InputBase
            fullWidth
            disabled={!switchChecked}
            type="number"
            id="intervalDay"
            onChange={handleIntervalChange_Day}
            value={interval_Day}
            className="input-base"
          />
          {switchChecked &&
            (interval_Day.length === 0 || parseInt(interval_Day) < 0) && (
              <div className="error-text">
                Please enter a valid "Day" number
              </div>
            )}
        </Grid>

        <Grid item xs={6} sm={3}>
          <label htmlFor="intervalHour" className="date-picker-label">
            {`pick hours${switchChecked ? "*" : ""}`}
          </label>
          <InputBase
            fullWidth
            disabled={!switchChecked}
            type="number"
            id="intervalHour"
            onChange={handleIntervalChange_Hour}
            value={sch1Interval_Hour}
            className="input-base"
          />
          {switchChecked &&
            (sch1Interval_Hour.length === 0 ||
              parseInt(sch1Interval_Hour) < 0) && (
              <div className="error-text">
                Please enter a valid "Hour" number
              </div>
            )}
        </Grid>

        <Grid item xs={6} sm={3}>
          <label htmlFor="intervalMinute" className="date-picker-label">
            pick minutes*
          </label>
          <InputBase
            fullWidth
            disabled={!switchChecked}
            type="number"
            id="intervalMinute"
            onChange={handleIntervalChange_Minute}
            value={sch1Interval_Minute}
            className="input-base"
          />
          {switchChecked &&
            (sch1Interval_Minute.length === 0 ||
              parseInt(sch1Interval_Minute) < 0) && (
              <div className="error-text">
                Please enter a valid "Minute" number
              </div>
            )}
        </Grid>

        <Grid item xs={6} sm={3}>
          <label htmlFor="intervalRepeat" className="date-picker-label">
            {`Repeat number${switchChecked ? "*" : ""}`}
          </label>
          <InputBase
            fullWidth
            disabled={!switchChecked}
            type="number"
            id="intervalRepeat"
            onChange={handleRepeatChange}
            value={repeat}
            className="input-base"
          />
          {switchChecked && (repeat.length === 0 || parseInt(repeat) < 0) && (
            <div className="error-text">
              Please enter a valid "Repeat" number
            </div>
          )}
        </Grid>

        {switchChecked && (
          <Grid item xs={12} sm={12}>
            <Alert severity={calculateNextValidDateAlertSeverity()}>
              Next valid date can start in {nextValidDate.days} day(s),{" "}
              {nextValidDate.hours} hours(s) and {nextValidDate.minutes}{" "}
              minute(s).
            </Alert>
          </Grid>
        )}

        {switchChecked && !Sche1RepDeletionIsValid && parseInt(repeat) === 0 && (
          <Grid item xs={12} sm={12}>
            <Alert severity="error">
              You Can not delete the repetition that has been started!
            </Alert>
          </Grid>
        )}

        {/* Preview Repetitions */}
        {switchChecked && repeat >= 1 ? (
          <Grid item xs={12} sm={12}>
            <PreviewRepetitions
              reps={reps}
              viewSchedulerMode={viewSchedulerMode}
              previewRepetitionAlertObj={previewRepetitionAlertObj}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default SchedualIntervalTime;
