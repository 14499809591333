import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Flatpickr from "react-flatpickr";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { formatDate } from "../services/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  calendarIcon: {
    marginLeft: "-30px",
    marginTop: "10px",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

function StartEndTime({
  startTimeDate,
  onStartTimeDateChange,
  endTimeDate,
  onEndTimeDateChange,
  preAcDate,
  preAcChecked,
}) {
  const classes = useStyles();

  const detectValidDates = useCallback(async () => {
    if (preAcChecked) {
      if (preAcDate > startTimeDate) {
        onStartTimeDateChange(preAcDate);
      }
    }
    if (startTimeDate > endTimeDate) {
      onEndTimeDateChange(startTimeDate);
    }
  }, [
    preAcDate,
    preAcChecked,
    startTimeDate,
    endTimeDate,
    onEndTimeDateChange,
    onStartTimeDateChange,
  ]);

  useEffect(() => {
    detectValidDates();
  }, [detectValidDates]);

  return (
    <div className={classes.gridRoot}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <label htmlFor="start-time" className="date-picker-label">
            Start Time *
          </label>
          <div style={{ display: "flex" }}>
            <Flatpickr
              id="start-time"
              value={startTimeDate}
              data-enable-time
              onChange={(e) => {
                if (e[0]) {
                  let userTime1 = e[0].getTime();
                  let time1 = formatDate(userTime1);
                  onStartTimeDateChange(time1);
                } else {
                  onStartTimeDateChange("");
                }
              }}
              className="date-picker-input"
              options={{
                minDate: preAcChecked ? preAcDate : null,
                disableMobile: "true",
                time_24hr: "true",
              }}
            />
            <CalendarTodayIcon
              fontSize="small"
              className={classes.calendarIcon}
            />
          </div>
          {startTimeDate?.length === 0 && (
            <div className="error-text">Please select a valid date</div>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <label htmlFor="end-time" className="date-picker-label">
            End Time *
          </label>
          <div style={{ display: "flex" }}>
            <Flatpickr
              id="end-time"
              value={endTimeDate}
              data-enable-time
              onChange={(e) => {
                if (e[0]) {
                  let userTime2 = e[0].getTime();
                  let time2 = formatDate(userTime2);
                  onEndTimeDateChange(time2);
                } else {
                  onEndTimeDateChange("");
                }
              }}
              className="date-picker-input"
              options={{
                minDate: startTimeDate,
                disableMobile: "true",
                time_24hr: "true",
              }}
            />
            <CalendarTodayIcon
              fontSize="small"
              className={classes.calendarIcon}
            />
          </div>
          {endTimeDate?.length === 0 && (
            <div className="error-text">Please select a valid date</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default StartEndTime;
