import axios from "axios";
import auth from "./authService";
import { getAPIUrl } from "./api";
import logger from "./logService";

// const apiUrl = process.env.REACT_APP_APIURL;
const apiUrl = getAPIUrl();

const user = auth.getCurrentUser();
const headers = {
  gamePackageName: user.packageName,
  env: user.env,
  username: user.username,
  Authorization: "Bearer " + user.token,
};

export async function addEvent(body, gamePackageName) {
  const addHeader = {
    ...headers,
    marketName: "CBFZE",
    // setStrictMarket: true,
    // gamePackageName: gamePackageName,
  };

  const result = await axios.post(apiUrl + "/event/add", body, {
    headers: addHeader,
  });
  logger.logInfo("result:", result);
}

export async function getAllEvents() {
  const params = {
    gamePackageName: user.packageName,
    env: user.env,
  };
  return axios.get(apiUrl + "/event/get-all", { params });
}

export async function getAllPackageName() {
  return axios.get(apiUrl + "/event/get-all/package-names");
}

export async function deleteEvent(id) {
  return axios.post(apiUrl + "/event/delete", id, { headers });
}

export async function getEventById(id, clientVersion) {
  const params = {
    id: id,
    clientVersion: clientVersion,
  };
  return axios.get(apiUrl + "/event/get", { params, headers });
}

export async function updateEvent(body, gamePackageName) {
  const updateHeader = {
    ...headers,
    marketName: "CBFZE",
    // setStrictMarket: true,
    uuid: "",
    // gamePackageName: gamePackageName,
  };

  return axios.post(apiUrl + "/event/update", body, { headers: updateHeader });
}

export async function addRepetition(body) {
  const addRepHeader = {
    ...headers,
    marketName: "CBFZE",
    uuid: "",
  };

  return axios.post(apiUrl + "/event/repetition/add", body, {
    headers: addRepHeader,
  });
}
