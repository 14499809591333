import { toast } from "react-toastify";

export function toasty(msg) {
  toast(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}

export function success(msg) {
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}
export function info(msg) {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}
export function error(msg) {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}
export function warning(msg) {
  toast.warn(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}
