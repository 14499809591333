import Login from "./components/login";
import MiniDrawer from "./components/drawer";
import ProtectedRoute from "./components/common/protectedRoute";

import { Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Particles from "react-particles-js";

import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_green.css";
import "../node_modules/flatpickr/dist/flatpickr.min.css";
import "./App.css";

const particleOptions = {
  particles: {
    number: {
      value: 60,
      density: {
        enable: true,
        value_area: 300,
      },
    },
    move: {
      speed: 1,
    },
  },
};

function App() {
  return (
    <div>
      <Particles className="particles" params={particleOptions} />
      <ToastContainer />
      <Switch>
        <ProtectedRoute exact path="/login" component={Login} />
        <ProtectedRoute path="/event" component={MiniDrawer} />
        <Redirect from="/" to="/event" exact />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
