import React, { Fragment, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Flatpickr from "react-flatpickr";

import PreviewRepetitions from "./common/previewRepetitions";

import {
  formatDate,
  timeDiffCalculation,
  addDays,
  addHours,
  addMinutes,
  Overlap,
} from "../services/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  switch: {
    alignSelf: "center",
  },
  gridRoot: {
    flexGrow: 1,
  },
  icon: {
    padding: "0.25rem",
    margin: "1.3rem 0.25rem",
  },
  calendarIcon: {
    marginLeft: "-30px",
    marginTop: "10px",
  },
  addBtn: {
    margin: "1.35rem 0.5rem",
  },
}));

function SchedualDate({
  onSwitchChange,
  switchChecked,
  mode,
  reps,
  schedule2TempDate,
  onSchedule2TempDateChange,
  onAddSchedule2Date,
  handleDeleteRep,
  viewSchedulerMode,
  setDoesSch2RepsOverlap,
  previewRepetitionAlertObj,
}) {
  const classes = useStyles();
  const [minDate, setMinDate] = useState(0);

  const DetectRepsOverlap = useCallback(async () => {
    setDoesSch2RepsOverlap(Overlap(reps));
  }, [setDoesSch2RepsOverlap, reps]);

  useEffect(() => {
    DetectRepsOverlap();
  }, [DetectRepsOverlap]);

  const calculateMinDate = useCallback(async () => {
    if (reps.length > 0) {
      let minDate = 0; // minDate is in ms format
      let lastEndTime = reps[reps.length - 1].endTime;
      minDate = formatDate(lastEndTime);
      if (reps[0].startPreActiveTime !== 0) {
        let lastPreActiveTime = reps[reps.length - 1].startPreActiveTime;
        let lastStartTime = reps[reps.length - 1].startTime;
        let diffs = timeDiffCalculation(
          formatDate(lastStartTime),
          formatDate(lastPreActiveTime)
        );
        minDate = addDays(minDate, diffs.days);
        minDate = addHours(minDate, diffs.hours);
        minDate = addMinutes(minDate, diffs.minutes);
      }
      // return minDate;
      // logger.logInfo("minDate::", minDate);
      setMinDate(minDate);
    }
  }, [reps]);

  useEffect(() => {
    calculateMinDate();
  }, [calculateMinDate]);

  const DisableAddBtn = () => {
    if (minDate > schedule2TempDate || !switchChecked) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <div className={classes.gridRoot}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} className={classes.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchChecked}
                  onChange={onSwitchChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Schedule2"
              labelPlacement="start"
              style={{ marginLeft: "0px" }}
              disabled={mode === "edit" ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <label htmlFor="start-time" className="date-picker-label">
              Pick A Start Time *
            </label>
            <Flatpickr
              disabled={!switchChecked}
              value={schedule2TempDate}
              data-enable-time
              onChange={(e) => {
                if (e[0]) {
                  let userTime = e[0].getTime();
                  let time = formatDate(userTime);
                  onSchedule2TempDateChange(time);
                } else {
                  onSchedule2TempDateChange("");
                }
              }}
              className="date-picker-input"
              style={{
                cursor: switchChecked ? "pointer" : "default",
              }}
              options={{
                disableMobile: "true",
                time_24hr: "true",
                minDate: minDate,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              className={classes.addBtn}
              variant="contained"
              onClick={onAddSchedule2Date}
              disabled={DisableAddBtn()}
            >
              Add
            </Button>
          </Grid>
          {/* Preview Repetitions */}
          {switchChecked && reps.length >= 2 ? (
            <Grid item xs={12} sm={12}>
              <PreviewRepetitions
                reps={reps}
                handleDeleteRep={handleDeleteRep}
                viewSchedulerMode={viewSchedulerMode}
                previewRepetitionAlertObj={previewRepetitionAlertObj}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Fragment>
  );
}

export default SchedualDate;
