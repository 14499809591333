import axios from "axios";
import jwtDecode from "jwt-decode";
import { getAPIUrl } from "./api";

import { convertToEpochTime } from "./TimeConvertor";

// const apiUrl = process.env.REACT_APP_APIURL;
const apiUrl = getAPIUrl();

export async function login(body) {
  const { data: response } = await axios.post(apiUrl + "/security/login", body);
  const decodedToken = jwtDecode(response["data"]["token"]);
  localStorage.setItem("tokenKey", response["data"]["token"]);
  localStorage.setItem("username", decodedToken.username);
  localStorage.setItem("packageName", decodedToken.packageName);
  localStorage.setItem("env", decodedToken.env);
  localStorage.setItem("exp", decodedToken.exp);
}

export function logout() {
  localStorage.removeItem("tokenKey");
  localStorage.removeItem("username");
  localStorage.removeItem("packageName");
  localStorage.removeItem("env");
  localStorage.removeItem("exp");
}

export async function autoLogout() {
  let crTime = convertToEpochTime(new Date().getTime());
  const currentTime = crTime["data"];
  const expDateUtc = localStorage.getItem("exp");
  if (currentTime > expDateUtc) {
    logout();
    window.location.reload();
    return true;
  } else {
    return false;
  }
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem("tokenKey");
    const username = localStorage.getItem("username");
    const packageName = localStorage.getItem("packageName");
    const env = localStorage.getItem("env");
    const exp = localStorage.getItem("exp");
    return { token, username, packageName, env, exp };
  } catch (error) {
    return null;
  }
}

const auth = {
  login,
  logout,
  autoLogout,
  getCurrentUser,
};

export default auth;
