import Alert from "@material-ui/lab/Alert";
import logger from "../../services/logService";

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <Alert severity="error">
        Something went wrong! Please contact Medrick support.
      </Alert>
    </div>
  );
}

export function myErrorHandler(error, info) {
  // Do something with the error
  // E.g. log to an error logging client here
  logger.logError("err in err-boundary:", error);
  logger.logError("info in err-boundary:", info);
}
