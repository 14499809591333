import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import auth from "../services/authService";
import { getAllPackageName } from "./../services/httpService";
import { error } from "../services/toastNotification";
import logger from "../services/logService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: "5rem 1rem",
    // padding: "0",
    // minWidth: "900px",
    // maxWidth: "900px",
    width: "auto",
    maxWidth: "700px",
    height: "auto",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    boxShadow: "0 10px 10px 10px rgba(0,0,0,0.2)",
    "@media (max-width: 1000px)": {
      margin: "1rem",
      width: "auto",
      minWidth: "auto",
    },
  },
  formControl: {
    textAlign: "left",
  },
  containerr: {
    margin: "2rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px 8px",
  },

  loginBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  errorContainer: {
    width: "auto",
    backgroundColor: "#df4759",
    textAlign: "left",
    borderRadius: "6px",
  },
  errorText: {
    fontSize: "14px",
    padding: "10px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allPackageName, setAllPackageName] = useState([""]);
  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
    env: "prod",
    gamePackageName: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [errors, setErrors] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      let response = await getAllPackageName();
      setAllPackageName(response["data"]["data"]);
    } catch (err) {
      error("Something went wrong!");
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTextChange = (name) => (event) => {
    setLoginInfo({ ...loginInfo, [name]: event.target.value });
  };

  const onLoginSubmit = async () => {
    setLoading(true);
    setErrors("");
    try {
      await auth.login(loginInfo);
      window.location = "/";
    } catch (ex) {
      logger.logError("ex:", ex);
      if (ex.toString().includes("403")) {
        setErrors("Access_denied");
      } else if (ex.toString().includes("406")) {
        setErrors("Username or password is wrong");
      } else {
        setErrors("Something went wrong, Please try again");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerr}>
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            onLoginSubmit();
          }}
        >
          <div className={classes.header}>
            <h1>Login</h1>
          </div>
          {errors && (
            <div className={classes.errorContainer}>
              <Typography className={classes.errorText} color="textSecondary">
                {errors}
              </Typography>
            </div>
          )}
          <TextField
            value={loginInfo.username}
            onChange={handleTextChange("username")}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            variant="outlined"
          />
          <TextField
            value={loginInfo.password}
            onChange={handleTextChange("password")}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={isShowPassword ? "text" : "password"}
            id="password"
            variant="outlined"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="show-password"
                    onClick={() => setIsShowPassword((value) => !value)}
                  >
                    {isShowPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={loginInfo.env}
            onChange={handleTextChange("env")}
            margin="normal"
            required
            fullWidth
            id="env"
            label="env"
            name="env"
            variant="outlined"
          />
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
            margin="normal"
          >
            <InputLabel id="gamePackageName">gamePackageName</InputLabel>
            <Select
              // labelId="gamePackageName"
              id="gamePackageName"
              value={loginInfo.gamePackageName}
              onChange={handleTextChange("gamePackageName")}
              label="gamePackageName"
            >
              {allPackageName.map((p) => (
                <MenuItem key={p} value={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            className={classes.loginBtn}
            fullWidth
            disabled={
              !(
                loginInfo.username.trim().length > 0 &&
                loginInfo.password.trim().length > 0 &&
                loginInfo.env.trim().length > 0 &&
                loginInfo.gamePackageName.trim().length > 0
              )
            }
            type="submit"
          >
            Login
          </Button>
          {loading && (
            <CircularProgress
              color="primary"
              className={classes.buttonProgress}
            />
          )}
        </form>
      </div>
    </div>
  );
}
