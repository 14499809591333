import React, {useEffect, useCallback} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Flatpickr from "react-flatpickr";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { formatDate } from "../services/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  switch: {
    alignSelf: "center",
  },
  gridRoot: {
    flexGrow: 1,
  },
  calendarIcon: {
    marginLeft: "-30px",
    marginTop: "10px",
  },
}));

function AbsolueEvent({
  switchChecked,
  onSwitchChange,
  date,
  onDateChange,
  datePickerLabel,
  maxDate,
  minDate,
  mode,
}) {
  const classes = useStyles();

  const detectValidDates = useCallback(async () => {     
    if(date > maxDate || date < minDate){
      onDateChange(maxDate);
    }
  }, [minDate, maxDate, date, onDateChange]);

  useEffect(() => {
    detectValidDates();
  }, [detectValidDates]);

  return (
    <div className={classes.gridRoot}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} className={classes.switch}>
          <FormControlLabel
            control={
              <Switch
                checked={switchChecked}
                onChange={onSwitchChange}
                name="checkedB"
                color="primary"
                disabled={mode=== 'edit' ? true : false}
              />
            }
            label={"Absolue Event"}
            labelPlacement="start"
            style={{ marginLeft: "0px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <label htmlFor="absolute-event" className="date-picker-label">
            {`${datePickerLabel}${switchChecked ? " *" : ""}`}
          </label>
          <div style={{ display: "flex" }}>
            <Flatpickr
              disabled={!switchChecked}
              id="absolute-event"
              value={date}
              data-enable-time
              onChange={(e) => {
                if (e[0]) {
                  let userTime = e[0].getTime();
                  let time = formatDate(userTime);
                  onDateChange(time);
                } else {
                  onDateChange("");
                }
              }}
              className="date-picker-input"
              style={{ cursor: switchChecked ? "pointer" : "default" }}
              options={{
                minDate: minDate,
                maxDate: maxDate,
                disableMobile: "true",
                time_24hr: "true",
              }}
            />
            <CalendarTodayIcon
              color={!switchChecked ? "disabled" : "action"}
              fontSize="small"
              className={classes.calendarIcon}
            />
          </div>
          {date?.length === 0 && switchChecked && (
            <div className="error-text">Please select a valid date</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AbsolueEvent;
