import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ListItem } from "@material-ui/core";

import ConfirmDialog from "./common/confirmDialog";
import { getAllEvents, deleteEvent } from "./../services/httpService";
import { toasty, error } from "../services/toastNotification";
import auth from "../services/authService";
import { convertToEpochTime } from "../services/TimeConvertor";
import logger from "../services/logService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: " 2rem",
    padding: "0",
    // minWidth: "900px",
    // maxWidth: "900px",
    width: "900px",
    borderRadius: "10px",
    // backgroundColor: "rgba(255, 255, 255, 0.9)",
    backgroundColor: "white",
    boxShadow: "0 10px 10px 10px rgba(0,0,0,0.2)",
    "@media (max-width: 1000px)": {
      margin: "1rem",
      width: "auto",
      minWidth: "auto",
    },
  },
  actionIconColor: {
    color: "#212121",
    cursor: "pointer",
  },
}));

function EventList({ onEditClick }) {
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cachedId, setCachedId] = useState(0);
  const [searchText] = useState(" ");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      // get all events
      let getAllResult = await getAllEvents();
      let data = getAllResult["data"]["data"];

      // get current time
      let currentDateTime = convertToEpochTime(new Date().getTime());

      // logger.logInfo("data:", data);

      // find out if the event has been terminated
      data = data.filter(
        (d) => d.repetitions[d.repetitions.length - 1].terminated === false
      );

      // sort events
      data = data.sort(function (a, b) {
        var dateA = a.updatedDate === 0 ? a.createdDate : a.updatedDate;
        var dateB = b.updatedDate === 0 ? b.createdDate : b.updatedDate;
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }
        return 0;
      });

      // claculate tags for each event
      data.forEach((element, index) => {
        let gpn = element["gamePackageName"].split(".");
        element.gamePackageName = gpn[gpn.length - 1];

        let durations = [];
        let rep = element.repetitions;

        // waiting

        durations.push({
          start: 1000000000,
          end:
            rep[0].startPreActiveTime !== 0
              ? rep[0].startPreActiveTime
              : rep[0].startTime,
          tag: "waiting",
        });
        // pre-actine / active
        for (let i = 0; i < rep.length; i++) {
          durations.push({
            start: rep[i].startTime,
            end: rep[i].endTime,
            tag: "active",
          });
          if (rep[i].startPreActiveTime !== 0) {
            durations.push({
              start: rep[i].startPreActiveTime,
              end: rep[i].startTime,
              tag: "pre-active",
            });
          }
        }
        // live
        for (let j = 0; j < rep.length - 1; j++) {
          durations.push({
            start: rep[j].endTime,
            end:
              rep[j + 1].startPreActiveTime === 0
                ? rep[j + 1].startTime
                : rep[j + 1].startPreActiveTime,
            tag: "live",
          });
        }
        // finish
        durations.push({
          start: rep[rep.length - 1].endTime,
          end: 99999999999,
          tag: "finish",
        });

        // logger.logInfo("durations:", durations);

        for (let k = 0; k < durations.length; k++) {
          if (
            currentDateTime >= durations[k].start &&
            currentDateTime < durations[k].end
          ) {
            element._tags = durations[k].tag;
          }
        }
      });
      setAllEvents(data);
    } catch (err) {
      error("Something went wrong!");
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const checkUserValidation = async () => {
      const result = await auth.autoLogout();
      if (!result) {
        fetchData();
      }
    };
    checkUserValidation();
  }, [fetchData]);

  const calcularColor = (event) => {
    switch (event) {
      case "waiting":
        return "#C0C0C0";
      case "active":
        return "#00B140";
      case "pre-active":
        return "#add8e6";
      case "finish":
        return "#dc3545";
      case "live":
        return "#ffc107";
      default:
        break;
    }
  };

  const handleDeleteEvent = async (dataIndex) => {
    const result = await auth.autoLogout();

    if (!result) {
      if (allEvents[dataIndex]["_tags"] === "waiting") {
        setOpenDeleteDialog(true);
        setCachedId(allEvents[dataIndex].id);
      } else {
        error(
          `Access denied! Can not delete event in ${allEvents[dataIndex]["_tags"]} mode`
        );
      }
    }
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    setOpenDeleteDialog(false);
    try {
      setLoading(false);
      await deleteEvent({ id: cachedId });
      fetchData();
      toasty("Event was deleted successfully");
    } catch (ex) {
      setLoading(false);
      logger.logError("ex:", ex);
      if (ex.toString().includes("403")) {
        error("Token_expired, Please login");
      } else {
        error("Something went wrong, Please try again");
      }
    }
  };

  const columns = [
    {
      name: "viewSchedulerMode",
      label: "viewSchedulerMode",
      options: { filter: false, display: false },
    },
    {
      label: "Status",
      name: "_tags",
      options: {
        filter: true,
        filterType: "checkbox",
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Chip
              label={allEvents[dataIndex]["_tags"]}
              key={allEvents[dataIndex]["id"]}
              style={{
                backgroundColor: calcularColor(allEvents[dataIndex]["_tags"]),
              }}
            />
          );
        },

        filterOptions: {
          names: [
            "waiting events",
            "pre-active events",
            "active events",
            "live events",
            "finish events",
          ],
          renderValue: (val) => {
            return (
              <div>
                <ListItem
                  style={{ width: "100%", display: "block !important" }}
                >
                  {val}
                </ListItem>
              </div>
            );
          },

          logic(_tags, filterVal) {
            const show =
              (filterVal.indexOf("waiting events") >= 0 &&
                _tags === "waiting") ||
              (filterVal.indexOf("pre-active events") >= 0 &&
                _tags === "pre-active") ||
              (filterVal.indexOf("active events") >= 0 && _tags === "active") ||
              (filterVal.indexOf("live events") >= 0 && _tags === "live") ||
              (filterVal.indexOf("finish events") >= 0 && _tags === "finish");
            return !show;
          },
        },
      },
    },

    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span
              style={{
                display: "block",
                overflowX: "auto",
                whiteSpace: "nowrap",
                minWidth: "100px",
                maxWidth: "400px",
                clear: "both",
              }}
            >
              {allEvents[dataIndex]["name"]}
            </span>
          );
        },
      },
    },
    {
      name: "env",
      label: "Env",
      options: { filter: false, display: false },
    },
    {
      name: "gamePackageName",
      label: "Package Name",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "marketName",
      Label: "Market Name",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "clientType",
      label: "Client Type",
      options: {
        display: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          // return allEvents[dataIndex]["name"].substr(0, 35);
          return allEvents[dataIndex]["clientType"].length > 35
            ? `${allEvents[dataIndex]["clientType"].substr(0, 35)}...`
            : allEvents[dataIndex]["clientType"];
        },
      },
    },
    {
      name: "configVersion",
      label: "Config Version",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "eventEndType",
      label: "Event End Type",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        // customBodyRenderLite: (dataIndex, rowIndex) => {
        // onEditClick(allEvents[dataIndex].id);
        // return (
        // <EventForm params={allEvents[dataIndex].id} />
        // <Link to={`/eventForm/${allEvents[dataIndex].id}`}><EditIcon className={classes.actionIconColor} /></Link>
        // );

        // },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton
              onClick={() => {
                onEditClick(allEvents[dataIndex].id);
              }}
            >
              <EditIcon className={classes.actionIconColor} />
            </IconButton>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton onClick={() => handleDeleteEvent(dataIndex)}>
              <DeleteForeverIcon className={classes.actionIconColor} />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "multiselect",
    responsive: "vertical",
    selectableRows: "none",
    // search: false,
    download: false,
    print: false,
    viewColumns: false,
    // enableNestedDataAccess: ".",
    draggableColumns: {
      enabled: true,
    },
    resizableColumns: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: "primary",
        variant: "outlined",
        className: "testClass123",
      };
    },
    page: 0,
    searchText: searchText.trim(),
    searchProps: {
      onBlur: (e) => {},
      onKeyUp: (e) => {},
    },
    searchPlaceholder: "Search ...",
    customSearch: (searchQuery, currentRow, columns, index) => {
      let isFound = false;
      currentRow.forEach((col, index) => {
        if (index === 1) {
          if (col.toString().toLowerCase().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
  };

  return (
    <div className={classes.root}>
      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          onClose={handleDeleteClose}
          onConfirm={handleDeleteConfirm}
          itemName="event"
        />
      )}

      <MUIDataTable
        title="Event list"
        data={allEvents}
        columns={columns}
        options={options}
      />
      {loading && (
        <CircularProgress color="primary" className="button-progress-sticky" />
      )}
    </div>
  );
}

export default EventList;
