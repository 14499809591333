import React from "react";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";

export default function GeneralInfo({
  eventId,
  clientEventType,
  configVersion,
  name,
  mdPackageNameInput,
  onPackageNameChange,
  onClientEventTypeChange,
  onConfigVersionChange,
  onNameChange,
  mode,
}) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <label htmlFor="name" className="date-picker-label">
            Name *
          </label>
          <InputBase
            fullWidth
            type="string"
            id="name"
            onChange={onNameChange}
            value={name}
            className="input-base"
          />
          {!(name?.trim()?.length > 0) && (
            <div className="error-text">Please enter a valid "Name"</div>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <label htmlFor="clientEventType" className="date-picker-label">
            Client Event Type *
          </label>
          <InputBase
            fullWidth
            type="string"
            id="clientEventType"
            onChange={onClientEventTypeChange}
            value={clientEventType}
            className="input-base"
          />
          {!(clientEventType?.trim()?.length > 0) && (
            <div className="error-text">
              Please enter a valid "Client Event Type"
            </div>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <label htmlFor="configVersion" className="date-picker-label">
            Config Version *
          </label>
          <InputBase
            fullWidth
            type="number"
            id="configVersion"
            onChange={onConfigVersionChange}
            value={configVersion}
            className="input-base"
          />
          {(parseInt(configVersion) < 0 || configVersion.length === 0) && (
            <div className="error-text">
              Please enter a valid "Config Version"
            </div>
          )}
        </Grid>

        {mode === "edit" && (
          <Grid item xs={12} sm={12}>
            <label htmlFor="gpn" className="date-picker-label">
              Game Package Name *
            </label>
            <InputBase
              fullWidth
              type="string"
              id="gpn"
              onChange={onPackageNameChange}
              value={mdPackageNameInput}
              disabled={true}
              //   className="input-base"
              className="input-base-disabled"
            />
            {mdPackageNameInput?.trim()?.length === 0 && (
              <div className="error-text">
                Please enter a valid "Game Package Name"
              </div>
            )}
          </Grid>
        )}

        {mode === "edit" && (
          <Grid item xs={12} sm={12}>
            <label htmlFor="eventId" className="date-picker-label">
              Event Id *
            </label>
            <InputBase
              fullWidth
              type="string"
              id="eventId"
              value={eventId}
              disabled={true}
              className="input-base-disabled"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
