import React, { Fragment, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import PreActivation from "./preActivation";
import StartEndTime from "./startEndTime";
import RelativeEvent from "./relativeEvent";
import AbsolueEvent from "./absoluteEvent";
import SchedualIntervalTime from "./schedualIntervalTime";
import SchedualDate from "./schedualDate";
import MetaData from "./metaData";
import GeneralInfo from "./generalInfo";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback, myErrorHandler } from "./common/errorBoundary";

import { toasty, error } from "../services/toastNotification";
import auth from "../services/authService";
import logger from "../services/logService";
import {
  addEvent,
  getEventById,
  updateEvent,
  addRepetition,
} from "./../services/httpService";
import generateRandomId from "../services/generateRandomId";
import {
  convertToEpochTime,
  formatDate,
  addDays,
  addHours,
  addMinutes,
  timeDiffCalculation,
  Contains,
  calculateRepHasStartedMode,
} from "../services/TimeConvertor";

import "./common/divider.css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3rem 2rem",
    padding: "0",
    // minWidth: "900px",
    // maxWidth: "900px",
    width: "900px",
    borderRadius: "10px",
    // backgroundColor: "rgba(255, 255, 255, 0.9)",
    backgroundColor: "white",
    boxShadow: "0 10px 10px 10px rgba(0,0,0,0.2)",
    "@media (max-width: 1000px)": {
      margin: "2rem 0.5rem",
      width: "auto",
      minWidth: "auto",
    },
  },
  invisibleDivider: {
    visibility: "hidden",
    margin: `${theme.spacing(2.5)}px 0`,
  },
  gridRoot: {
    flexGrow: 1,
  },
  listItem: {
    display: "block",
    textAlign: "left",
  },
}));

function EventForm({ paramId, match }) {
  const classes = useStyles();

  // Error Boundary
  const [boundaryErrors, setBoundaryErrors] = useState(false);

  const errorHandling = (ex) => {
    logger.logError("ex:", ex);
    if (ex.toString().includes("401")) {
      return error("User Unauthorized");
    } else if (ex.toString().includes("403")) {
      return error("Access_denied");
    } else if (ex.toString().includes("404")) {
      return error("Not Found");
    } else {
      return error("Something went wrong! Please try again.");
    }
  };

  // add req
  const [waitingForReq, setWaitingForReq] = useState(false);

  // Pre Activation
  const [preAcChecked, setPreAcChecked] = useState(false);
  const [preAcDate, setPreAcDate] = useState(formatDate(new Date().getTime()));

  const handlePreAcCheckboxChange = (event) => {
    setPreAcChecked(event.target.checked);
  };

  const handlePreAcDateChange = (event) => {
    setPreAcDate(event);
  };

  // Start Time & End Time
  const [startTimeDate, setStTiDate] = useState(
    formatDate(new Date().getTime())
  );
  const handleStartTimeDateChange = (event) => {
    setStTiDate(event);
    if (event > endTimeDate) {
      setEnTiDate(0);
    }
  };

  const [endTimeDate, setEnTiDate] = useState(formatDate(new Date().getTime()));
  const handleEndTimeDateChange = (event) => {
    setEnTiDate(event);
  };

  //   Relative Event
  const [relEvSwitch, setRelEvSwitch] = useState(true);
  const [eventDuration, setEventDuration] = useState(1);
  const [relEvDate, setRelEvDate] = useState(formatDate(new Date().getTime()));

  const handleRelEvSwitchChange = (event) => {
    setRelEvSwitch(event.target.checked);
    setAbEvSwitch(relEvSwitch);
  };

  const handleRelEvInputChanged = (event) => {
    setEventDuration(event.target.value);
  };

  const handleRelEvDateChange = (event) => {
    setRelEvDate(event);
  };

  // Absolute Event
  const [abEvSwitch, setAbEvSwitch] = useState(false);
  const [abEvDate, setAbEvDate] = useState(formatDate(new Date().getTime()));

  const handleAbEvSwitchChange = (event) => {
    setAbEvSwitch(event.target.checked);
    setRelEvSwitch(abEvSwitch);
  };

  const handleAbEvDateChange = (event) => {
    setAbEvDate(event);
  };

  const [viewSchedulerMode, setViewSchedulerMode] = useState(null);
  // Schedual Interval Time (Schedual1)
  const [numberOfStartedReps, setNumberOfStartedReps] = useState(0);
  const [Sche1RepDeletionIsValid, setSche1RepDeletionIsValid] = useState(true);
  const [schIntTiSwitch, setSchIntTiSwitch] = useState(true);
  const [sch1Interval_Day, setSch1Interval_Day] = useState(0);
  const [sch1Interval_Hour, setSch1Interval_Hour] = useState(0);
  const [sch1Interval_Minute, setSch1Interval_Minute] = useState(0);
  const [sch1Repeat, setSch1Repeat] = useState(0);
  const [hasPropsTouchedInSch1, setHasPropsTouchedInSch1] = useState(false);
  const [isSch1NextRepetitionValid, setIsSch1NextRepetitionValid] =
    useState(true);

  const handleSchIntTiSwitchChange = (event) => {
    setSchIntTiSwitch(event.target.checked);
    setSchDateSwitch(schIntTiSwitch);
  };

  const handleSetSch1IntervalChange_Day = (event) => {
    setSch1Interval_Day(event.target.value);
    setHasPropsTouchedInSch1(true);
  };

  const handleSetSch1IntervalChange_Hour = (event) => {
    setSch1Interval_Hour(event.target.value);
    setHasPropsTouchedInSch1(true);
  };

  const handleSetSch1IntervalChange_Minute = (event) => {
    setSch1Interval_Minute(event.target.value);
    setHasPropsTouchedInSch1(true);
  };

  const handleSetSch1RepeatChange = (event) => {
    setSch1Repeat(event.target.value);
  };

  const onSch1NextRepValidation = (event) => {
    setIsSch1NextRepetitionValid(event);
  };

  // Schedual Date (Schedual2)
  const [schDateSwitch, setSchDateSwitch] = useState(false);
  // this variable holds the value of schude2 "starttimes" with a client generated "id"
  const [schedule2StartTimes, setSchedule2StartTimes] = useState([]);
  // this variable holds the value of schedule2 date picker. minDate is calculated in schedualDate component.
  const [schedule2TempDate, setSchedule2TempDate] = useState(
    formatDate(new Date().getTime())
  );
  const [doesSch2RepsOverlap, setDoesSch2RepsOverlap] = useState(false);

  const handleAddSchedule2Date = () => {
    setSchedule2StartTimes([
      ...schedule2StartTimes,
      { content: schedule2TempDate, id: generateRandomId("s2-") },
    ]);
  };

  const handleSchedule2TempDateChange = (event) => {
    setSchedule2TempDate(event);
  };

  const handleSchDateSwitchChange = (event) => {
    setSchDateSwitch(event.target.checked);
    setSchIntTiSwitch(schDateSwitch);
  };

  const onDeleteSche2Rep = (dataIndex) => {
    // this delete handle is for shedule 2 only
    let localRep = [...schedule2StartTimes];
    localRep = localRep.filter((lr, index) => index !== dataIndex);
    setSchedule2StartTimes(localRep);
  };

  const QuantifySchedulerMode = useCallback(async () => {
    if (schIntTiSwitch) {
      setViewSchedulerMode("schedule1");
    }
    if (schDateSwitch) {
      setViewSchedulerMode("schedule2");
    }
  }, [schIntTiSwitch, schDateSwitch]);

  useEffect(() => {
    QuantifySchedulerMode();
  }, [QuantifySchedulerMode]);

  // general Info
  const [mdClientEventType, setMDClientEventType] = useState("SpecialOffer");
  const [mdConfigVersion, setMDConfigVersion] = useState(0);
  const [mdName, setMDName] = useState("Sunflower");
  const [mdPackageNameInput, setMDPackageNameInput] =
    useState("game packge name");

  // Meta Data
  const [versionMetadata, setVersionMetadata] = useState([]);
  const [mdMinBundle, setMDMinBundle] = useState(0);
  const [mdMaxBundle, setMDMaxBundle] = useState(0);
  const [mdConfig, setMDConfig] = useState(JSON.stringify({}));

  const handleClientEventTypeChange = (event) => {
    setMDClientEventType(event.target.value);
  };

  const handleConfigVersionChange = (event) => {
    setMDConfigVersion(event.target.value);
  };

  const handleNameChange = (event) => {
    setMDName(event.target.value);
  };

  const handlePackageNameChange = (event) => {
    setMDPackageNameInput(event.target.value);
  };

  const handleMDMinBundleChange = (event) => {
    setMDMinBundle(event.target.value);
  };

  const handleMDMaxBundleChange = (event) => {
    setMDMaxBundle(event.target.value);
  };

  const handleMDConfigChange = (event) => {
    setMDConfig(event);
  };

  const handleAddMetaData = (event) => {
    setVersionMetadata([
      ...versionMetadata,
      {
        id: generateRandomId("md"),
        mdMinBundle: mdMinBundle,
        mdMaxBundle: mdMaxBundle,
        mdConfig: mdConfig,
      },
    ]);
    setMDMinBundle(0);
    setMDMaxBundle(0);
    setMDConfig(JSON.stringify({}));
    toasty("The metadata version was added successfully!");
  };

  const handleVersionMetadaDelete = (key) => {
    let metadata = [...versionMetadata];
    metadata = metadata.filter((md) => md.id !== key);
    setVersionMetadata(metadata);
    toasty("The metadata version was deleted successfully!");
  };

  const handleMetadataEdit = (id, key, value) => {
    const metadata = [...versionMetadata];
    metadata.forEach((md) => {
      if (md.id === id) {
        md[key] = value;
      }
    });
    setVersionMetadata(metadata);
  };

  // check to see whether we are in "add" or "edit" mode
  const [mode, setMode] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventRepEditMode, setEventRepEditMode] = useState([]);
  const [calculateReps, setCalculateReps] = useState([]);
  const [originalRep, setOriginalRep] = useState([]);

  const detectMode = useCallback(async () => {
    setWaitingForReq(true);
    try {
      // const eventId = props.match.params.id;
      const eventId = paramId;
      if (eventId === "new") {
        setMode("add");
        setCalculateReps([]);
        setOriginalRep([]);
        setMDName("Sunflower");
        setMDClientEventType("SpecialOffer");
        setMDConfigVersion(0);
        setVersionMetadata([]);
        setMDMinBundle(0);
        setMDMaxBundle(0);
        setMDConfig(JSON.stringify({}));
        setSchDateSwitch(false);
        setSchedule2StartTimes([]);
        setSchIntTiSwitch(true);
        setViewSchedulerMode("schedule1");
        setHasPropsTouchedInSch1(false);
        setSch1Interval_Day(0);
        setSch1Interval_Hour(0);
        setSch1Interval_Minute(0);
        setSch1Repeat(0);
        setAbEvSwitch(false);
        setAbEvDate(formatDate(new Date().getTime()));
        setRelEvSwitch(true);
        setEventDuration(1);
        setEnTiDate(formatDate(new Date().getTime()));
        setRelEvDate(formatDate(new Date().getTime()));
        setStTiDate(formatDate(new Date().getTime()));
        setPreAcChecked(false);
        setPreAcDate(formatDate(new Date().getTime()));
        return;
      } else {
        setMode("edit");
        const result = await getEventById(eventId, "");
        const event = result["data"]["data"];

        logger.logInfo("event:", event);

        /* --------------
        set the times to zero to prevent bugs.
        then load the times in this order: endtime, starttime, preactime
         ---------------*/
        setPreAcDate(0);
        setStTiDate(0);
        setEnTiDate(0);
        //
        setHasPropsTouchedInSch1(false);
        setEventId(event["id"] ? event["id"] : "");
        setEventRepEditMode(event["repetitions"] ? event["repetitions"] : []);
        setOriginalRep(calculateRepHasStartedMode(event["repetitions"]));
        setMDName(event["name"] ? event["name"] : "");
        setMDPackageNameInput(
          event["gamePackageName"] ? event["gamePackageName"] : ""
        );
        setMDConfigVersion(event["configVersion"] ? event["configVersion"] : 0);
        setMDClientEventType(event["clientType"] ? event["clientType"] : "");
        // format versionMetadata
        let vmt = [];
        Object.keys(event["versionMetaData"]).forEach((key) => {
          let bundles = key.split("-");
          vmt.push({
            mdMinBundle: parseInt(bundles[0]),
            mdMaxBundle: parseInt(bundles[1]),
            mdConfig: JSON.stringify(event["versionMetaData"][key]),
            id: generateRandomId("md"),
          });
        });
        setVersionMetadata(vmt);

        setRelEvSwitch(event["eventEndType"] === "RELATIVE" ? true : false);
        setEventDuration(
          event["periodTimeForMiddleJoinTillEnd"]
            ? parseInt(event["periodTimeForMiddleJoinTillEnd"]) / 3600
            : 0
        );
        setAbEvSwitch(event["eventEndType"] === "ABSOLUTE" ? true : false);

        let reps = event["repetitions"];
        // the order of fetching data is important
        setEnTiDate(
          reps[0]["endTime"] !== 0 ? formatDate(reps[0]["endTime"]) : 0
        );
        setStTiDate(
          reps[0]["startTime"] !== 0 ? formatDate(reps[0]["startTime"]) : 0
        );
        setPreAcChecked(reps[0]["startPreActiveTime"] ? true : false);
        setPreAcDate(
          reps[0]["startPreActiveTime"] !== 0
            ? formatDate(reps[0]["startPreActiveTime"])
            : formatDate(reps[0]["startTime"])
        );
        setAbEvDate(
          event["eventEndType"] === "ABSOLUTE"
            ? formatDate(reps[0]["endJoinTime"])
            : ""
        );
        setRelEvDate(
          event["eventEndType"] === "RELATIVE"
            ? formatDate(reps[0]["endJoinTime"])
            : ""
        );
        // calculating scheduals
        setViewSchedulerMode(event["viewSchedulerMode"]);
        if (
          event["viewSchedulerMode"] === null ||
          event["viewSchedulerMode"] === undefined ||
          !event["viewSchedulerMode"]
        ) {
          // this calculation is for old event that does not contain "viewSchedulerMode" field
          if (reps.length === 1) {
            setSchDateSwitch(false);
            setSchedule2StartTimes([]);
            setSchIntTiSwitch(true);
            setSch1Repeat(0);
            setSch1Interval_Day(0);
            setSch1Interval_Hour(0);
            setSch1Interval_Minute(0);
            setViewSchedulerMode("schedule1");
          } else if (reps.length === 2) {
            /*
          setSchDateSwitch(false);          
          setSchedule2StartTimes([]);
          setSchIntTiSwitch(true);
          setSch1Repeat(1);
          let timeDiffCalc = timeDiffCalculation(
            new Date(
              getDate(new Date(convertToUtcFormat(reps[1]["startTime"])), false)
            ),
            new Date(
              getDate(new Date(convertToUtcFormat(reps[0]["startTime"])), false)
            )
          );

          setSch1Interval_Day(timeDiffCalc["days"]);
          setSch1Interval_Hour(timeDiffCalc["hours"]);
          setSch1Interval_Minute(timeDiffCalc["minutes"]);          
          */

            setSchIntTiSwitch(false);
            setSch1Repeat(0);
            setSch1Interval_Day(0);
            setSch1Interval_Hour(0);
            setSch1Interval_Minute(0);
            setSchDateSwitch(true);
            let dates = [];
            reps.forEach((r, index) => {
              if (index >= 1) {
                dates.push({
                  id: generateRandomId("2rep-"),
                  content: formatDate(r["startTime"]),
                });
              }
            });
            setSchedule2StartTimes(dates);
            setViewSchedulerMode("schedule2");
          } else if (reps.length >= 3) {
            let diffs = [];
            for (let i = 1; i < reps.length; i++) {
              diffs.push(
                timeDiffCalculation(
                  formatDate(reps[i]["startTime"]),
                  formatDate(reps[i - 1]["startTime"])
                )
              );
            }
            // logger.logInfo("diffs:", diffs);

            if (JSON.stringify(diffs[0]) === JSON.stringify(diffs[1])) {
              // sch1
              setSchDateSwitch(false);
              setSchedule2StartTimes([]);
              setSchIntTiSwitch(true);
              setSch1Repeat(reps.length - 1);
              setSch1Interval_Day(diffs[0]["days"]);
              setSch1Interval_Hour(diffs[0]["hours"]);
              setSch1Interval_Minute(diffs[0]["minutes"]);
              setViewSchedulerMode("schedule1");
            } else {
              // sch2
              setSchIntTiSwitch(false);
              setSch1Repeat(0);
              setSch1Interval_Day(0);
              setSch1Interval_Hour(0);
              setSch1Interval_Minute(0);
              setSchDateSwitch(true);
              let dates = [];
              reps.forEach((r, index) => {
                if (index >= 1) {
                  dates.push({
                    id: generateRandomId("sh2-"),
                    content: formatDate(r["startTime"]),
                  });
                }
              });
              setSchedule2StartTimes(dates);
              setViewSchedulerMode("schedule2");
            }
          }
        } else {
          // accures when event has "viewSchedulerMode" field
          if (event["viewSchedulerMode"] === "schedule1") {
            // schedule type 1 detected
            // 1
            setSchIntTiSwitch(true);
            setSch1Repeat(reps.length - 1);
            if (reps.length === 1) {
              setSch1Interval_Day(0);
              setSch1Interval_Hour(0);
              setSch1Interval_Minute(0);
            } else {
              let timeDifferences = timeDiffCalculation(
                formatDate(reps[1]["startTime"]),
                formatDate(reps[0]["startTime"])
              );
              setSch1Interval_Day(timeDifferences.days);
              setSch1Interval_Hour(timeDifferences.hours);
              setSch1Interval_Minute(timeDifferences.minutes);
            }
            // 2
            setSchedule2StartTimes([]);
            setSchDateSwitch(false);
          } else {
            // schedule type 2 detected
            // 1
            setSchIntTiSwitch(false);
            setSch1Repeat(0);
            setSch1Interval_Day(0);
            setSch1Interval_Hour(0);
            setSch1Interval_Minute(0);
            // 2
            setSchDateSwitch(true);
            let sch2Dates = [];
            reps.forEach((r, index) => {
              if (index >= 1) {
                sch2Dates.push({
                  id: generateRandomId("sh2-"),
                  content: formatDate(r["startTime"]),
                });
              }
            });
            setSchedule2StartTimes(sch2Dates);
          }
        }
      }
    } catch (ex) {
      errorHandling(ex);
    } finally {
      setWaitingForReq(false);
    }
  }, [paramId]);

  const calculateRepetitions = useCallback(() => {
    let reps = [];

    let timeGapBetweenPreAcAndEndTime = (endTimeDate - preAcDate) / 60000; //(diff in ms)/60000 => to convert to minute
    let timeGapBetweenAbsoluteEndJoinTimeAndEndTime =
      (endTimeDate - abEvDate) / 60000;
    let timeGapBetweenRelativeEndJoinTimeAndEndTime =
      (endTimeDate - relEvDate) / 60000;
    let timeGapBetweenStartEndTime = (endTimeDate - startTimeDate) / 60000;
    let endTime = endTimeDate;

    const calculateTheRepetition = () => {
      reps.push({
        terminated: false,
        eventReputationUuId: "",
        startPreActiveTime: preAcChecked
          ? addMinutes(endTime, -timeGapBetweenPreAcAndEndTime)
          : 0,
        startTime: addMinutes(endTime, -timeGapBetweenStartEndTime),
        startJoinTime: addMinutes(endTime, -timeGapBetweenStartEndTime),
        endTime: endTime,
        endJoinTime: abEvSwitch
          ? addMinutes(endTime, -timeGapBetweenAbsoluteEndJoinTimeAndEndTime)
          : addMinutes(endTime, -timeGapBetweenRelativeEndJoinTimeAndEndTime),
        customFields: {
          id: generateRandomId("cf"),
          hasStarted: false,
        },
      });
    };

    if (schIntTiSwitch) {
      // schedual 1
      for (let i = 0; i < parseInt(sch1Repeat) + 1; i++) {
        calculateTheRepetition();

        endTime = addDays(endTime, sch1Interval_Day);
        endTime = addHours(endTime, sch1Interval_Hour);
        endTime = addMinutes(endTime, sch1Interval_Minute);
      }
    } else {
      // schedual 2
      for (let i = 0; i < schedule2StartTimes.length + 1; i++) {
        calculateTheRepetition();

        endTime = addMinutes(
          schedule2StartTimes[i]?.content,
          timeGapBetweenStartEndTime
        );
      }
    }

    // convert reps to epochtime
    reps.forEach((r) => {
      Object.keys(r).forEach((key) => {
        if (typeof r[key] === "number") {
          r[key] = convertToEpochTime(r[key]);
        }
      });
    });

    // determin if the rep has been started, if so it can not be deleted.
    reps = calculateRepHasStartedMode(reps);

    /* chnges in edit mode -- rep uuid */
    if (mode === "edit") {
      for (let i = 0; i < reps.length; i++) {
        reps[i].eventReputationUuId = eventRepEditMode[i]?.eventReputationUuId
          ? eventRepEditMode[i]?.eventReputationUuId
          : "";
      }
    }

    // logger.logInfo("reps::EF", reps);
    setCalculateReps(reps);
  }, [
    preAcDate,
    preAcChecked,
    startTimeDate,
    endTimeDate,
    abEvDate,
    relEvDate,
    abEvSwitch,
    schIntTiSwitch,
    sch1Repeat,
    sch1Interval_Day,
    sch1Interval_Hour,
    sch1Interval_Minute,
    schedule2StartTimes,
    mode,
    eventRepEditMode,
  ]);

  useEffect(() => {
    detectMode();
  }, [detectMode]);

  useEffect(() => {
    calculateRepetitions();
  }, [calculateRepetitions]);

  /* ----------- 
  originalRep - represents the original repetitions array that we get from server.
  calculateReps - represents the repetitions array generated by the user.
  if the rep has been started, can not be edited unless the edited version contains the original one, without conflicts.
  -----------  */

  /* ----------- 
  Conflicts are checked in 2 ways:
  - DetectIfRepsConflict func - common in sch1 & sch2 - detect wethear the started rep has a valid change or not
  - isSch1NextRepetitionValid - sch1 - checks if reps overlap
  - doesSch2RepsOverlap - sch2 - checks if reps overlap
  -----------  */

  const [repsConflictErr, setRepsConflictErr] = useState(false);
  const DetectIfRepsConflict = useCallback(() => {
    let repsConflictErr = 0;
    if (numberOfStartedReps > 0) {
      if (originalRep.length !== 0 && calculateReps.length !== 0) {
        for (let i = 0; i < originalRep.length; i++) {
          if (originalRep[i].customFields.hasStarted === true) {
            let doesContain = Contains(originalRep[i], calculateReps[i]);
            if (!doesContain) repsConflictErr--;
          }
        }
      }
    }
    setRepsConflictErr(repsConflictErr < 0 ? true : false);
  }, [calculateReps, originalRep, numberOfStartedReps, setRepsConflictErr]);

  useEffect(() => {
    DetectIfRepsConflict();
  }, [DetectIfRepsConflict]);

  /*----------- 
  The Number Repetitions that has been started should be calculated from
  the original event.repetitions that we get from the server.
  if originalRep was not needed for this operation, move this concept to calculateRepetitions();
  ------------*/
  const calculateNumberOfStartedReps = useCallback(() => {
    let num = 0;
    let localOriginalRep = [...originalRep];
    localOriginalRep.forEach((rep) => {
      if (rep["customFields"]["hasStarted"]) num++;
    });
    setNumberOfStartedReps(num);
  }, [originalRep]);

  useEffect(() => {
    calculateNumberOfStartedReps();
  }, [calculateNumberOfStartedReps]);

  /*----------- 
  schedule1 repetitions can only be deleted from the end of the queue.
  So, deletion is just controlled by "repeat" field (inorder to delete a rep, minus the "repeat").
  Also, the reps which has been started can be deleted, next few lines implement this feature:
  ------------*/
  const detectIfSch1RepeatIsValidForDeletion = useCallback(() => {
    setSche1RepDeletionIsValid(
      sch1Repeat < numberOfStartedReps - 1 ? false : true
    );
  }, [sch1Repeat, numberOfStartedReps]);

  useEffect(() => {
    detectIfSch1RepeatIsValidForDeletion();
  }, [detectIfSch1RepeatIsValidForDeletion]);

  /*----------- 
  Set an appropriate error/warning message for previewRepetitions alert.
  ------------*/
  const [previewRepetitionAlertObj, setpreviewRepetitionAlertObj] = useState({
    msg: "",
    severity: "",
  });
  const setPreviewRepetitionAlertObj = useCallback(() => {
    if (repsConflictErr || (!Sche1RepDeletionIsValid && schIntTiSwitch)) {
      setpreviewRepetitionAlertObj({
        msg: "You Can not modify the repetition that has been started!",
        severity: "error",
      });
    } else if (
      (schIntTiSwitch && !isSch1NextRepetitionValid) ||
      (schDateSwitch && doesSch2RepsOverlap)
    ) {
      setpreviewRepetitionAlertObj({
        msg: "Repetitions overlap!",
        severity: "error",
      });
    } else {
      setpreviewRepetitionAlertObj({
        msg: "Gray rows have been started. Therefore they can not be deleted!",
        severity: "warning",
      });
    }
  }, [
    repsConflictErr,
    isSch1NextRepetitionValid,
    doesSch2RepsOverlap,
    Sche1RepDeletionIsValid,
    schIntTiSwitch,
    schDateSwitch,
  ]);

  useEffect(() => {
    setPreviewRepetitionAlertObj();
  }, [setPreviewRepetitionAlertObj]);

  // Submit
  const handleFormSubmit = async () => {
    const result = await auth.autoLogout();
    if (result) return;

    setWaitingForReq(true);
    let mappedVersionMetaData = {};
    versionMetadata.forEach((element) => {
      if (!element.mdMinBundle) element.mdMinBundle = 0;
      if (!element.mdMaxBundle) element.mdMaxBundle = 0;
      if (!element.mdConfig) element.mdConfig = JSON.stringify({});
      let key = `${element.mdMinBundle}-${element.mdMaxBundle}`;
      mappedVersionMetaData[key] = JSON.parse(element.mdConfig);
    });

    let requestBody = {
      clientEventType: mdClientEventType,
      configVersion: parseInt(mdConfigVersion),
      eventEndType: abEvSwitch ? "ABSOLUTE" : "RELATIVE",
      name: mdName,
      periodTimeForMiddleJoinTillEnd: relEvSwitch
        ? parseInt(eventDuration) * 3600
        : 0,
      states: {},
      versionMetaData: mappedVersionMetaData,
      viewSchedulerMode: viewSchedulerMode,
      repetitions: calculateReps,
    };

    /* remove customFields in submit phase. */
    for (let i = 0; i < requestBody.repetitions.length; i++) {
      delete requestBody["repetitions"][i]["customFields"];
    }

    // chnges in edit mode
    if (mode === "edit") {
      requestBody.id = eventId;
    }

    logger.logInfo("requestBody:", requestBody);

    /* --------------- 
    invode AddRep requests just in edit mode. (add mode handles addRepUuid itself)
    if uuid is empty, corrensponding req is called.
    --------------- */
    let localReps = [];
    if (mode === "edit") {
      let addRepResult = [...requestBody.repetitions];
      for (let i = 0; i < requestBody.repetitions.length; i++) {
        let currentRep = requestBody.repetitions[i];
        if (currentRep.eventReputationUuId === "") {
          let body = {
            id: requestBody.id,
            repetition: {
              endJoinTime: currentRep.endJoinTime,
              endTime: currentRep.endTime,
              eventReputationUuId: "",
              startJoinTime: currentRep.startJoinTime,
              startPreActiveTime: currentRep.startPreActiveTime,
              startTime: currentRep.startTime,
              terminated: false,
            },
          };
          logger.logInfo("body::", body);
          try {
            const result = await addRepetition(body);
            logger.logInfo("result-addRep:", result);
            addRepResult = result.data.data.repetitions;
            for (let j = addRepResult.length - 1; j >= 0; j--) {
              if (addRepResult[j].startTime === body.repetition.startTime) {
                body.repetition.eventReputationUuId =
                  addRepResult[j].eventReputationUuId;
                break;
              }
            }
            localReps.push(body.repetition);
          } catch (error) {
            requestBody.repetitions = calculateReps;
            logger.logError("error-addRep:", error);
            error("Something went wrong during adding repetitions!");
          }
        } else {
          localReps.push(currentRep);
        }
      }
      requestBody.repetitions = localReps;
    }

    // invoke Add/Edit requests
    try {
      if (mode === "add") {
        await addEvent(requestBody, mdPackageNameInput);
      } else if (mode === "edit") {
        await updateEvent(requestBody, mdPackageNameInput);
      }
      toasty(`Event was successfully ${mode}ed`);
      window.location = "/";
    } catch (ex) {
      errorHandling(ex);
    } finally {
      setWaitingForReq(false);
    }
  };

  const onSubmitDisable = () => {
    if (
      boundaryErrors ||
      repsConflictErr ||
      (preAcChecked && preAcDate?.length === 0) ||
      startTimeDate.length === 0 ||
      endTimeDate.length === 0 ||
      (relEvSwitch &&
        (parseInt(eventDuration) <= 0 || eventDuration.length === 0)) ||
      (abEvSwitch && abEvDate.length === 0) ||
      (relEvSwitch && relEvDate.length === 0) ||
      mdName.trim().length === 0 ||
      mdClientEventType.trim().length === 0 ||
      mdPackageNameInput.trim().length === 0 ||
      parseInt(mdConfigVersion) < 0 ||
      mdConfigVersion.length === 0
    ) {
      return true;
    }

    if (schIntTiSwitch) {
      if (
        parseInt(sch1Repeat) < 0 ||
        sch1Repeat.length === 0 ||
        parseInt(sch1Interval_Day) < 0 ||
        sch1Interval_Day.length === 0 ||
        parseInt(sch1Interval_Hour) < 0 ||
        sch1Interval_Hour.length === 0 ||
        parseInt(sch1Interval_Minute) < 0 ||
        sch1Interval_Minute.length === 0 ||
        !isSch1NextRepetitionValid ||
        !Sche1RepDeletionIsValid
      ) {
        return true;
      }
    }

    if (schDateSwitch) {
      if (doesSch2RepsOverlap) {
        return true;
      }
    }

    return false;
  };

  return (
    <Fragment>
      <List className={classes.root}>
        <div className="divider divider-margin divider-padding">
          <span className="divider-line hide-divider-line"></span>
          <span className="divider-content">general info</span>
          <span className="divider-line hide-divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <GeneralInfo
              eventId={eventId}
              clientEventType={mdClientEventType}
              onClientEventTypeChange={handleClientEventTypeChange}
              configVersion={mdConfigVersion}
              onConfigVersionChange={handleConfigVersionChange}
              name={mdName}
              onNameChange={handleNameChange}
              mdPackageNameInput={mdPackageNameInput}
              onPackageNameChange={handlePackageNameChange}
              mode={mode}
            />
          </ErrorBoundary>
        </ListItem>

        <div className="divider divider-margin">
          <span className="divider-line"></span>
          <span className="divider-content">pre activation</span>
          <span className="divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <PreActivation
              checked={preAcChecked}
              date={preAcDate}
              onCheckboxChange={handlePreAcCheckboxChange}
              onDateChange={handlePreAcDateChange}
              ceckboxLabel="Pre-Activation"
              datePickerLabel="Pre-Activation Start Time"
            />
          </ErrorBoundary>
        </ListItem>

        <div className="divider divider-margin">
          <span className="divider-line"></span>
          <span className="divider-content">start/end time</span>
          <span className="divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <StartEndTime
              startTimeDate={startTimeDate}
              endTimeDate={endTimeDate}
              preAcDate={preAcDate}
              preAcChecked={preAcChecked}
              onStartTimeDateChange={handleStartTimeDateChange}
              onEndTimeDateChange={handleEndTimeDateChange}
            />
          </ErrorBoundary>
        </ListItem>

        <div className="divider divider-margin">
          <span className="divider-line"></span>
          <span className="divider-content">event type</span>
          <span className="divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <RelativeEvent
              handleInputChange={handleRelEvInputChanged}
              inputLabel="Event Duration (hours)"
              value={eventDuration}
              onSwitchChange={handleRelEvSwitchChange}
              switchChecked={relEvSwitch}
              date={relEvDate}
              onDateChange={handleRelEvDateChange}
              maxDate={endTimeDate}
              minDate={preAcChecked ? preAcDate : startTimeDate}
              mode={mode}
            />
          </ErrorBoundary>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <AbsolueEvent
              switchChecked={abEvSwitch}
              onSwitchChange={handleAbEvSwitchChange}
              date={abEvDate}
              onDateChange={handleAbEvDateChange}
              datePickerLabel="End Join Time"
              maxDate={endTimeDate}
              minDate={preAcChecked ? preAcDate : startTimeDate}
              mode={mode}
            />
          </ErrorBoundary>
        </ListItem>

        <div className="divider divider-margin">
          <span className="divider-line"></span>
          <span className="divider-content">scheduling</span>
          <span className="divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <SchedualIntervalTime
              onSwitchChange={handleSchIntTiSwitchChange}
              switchChecked={schIntTiSwitch}
              interval_Day={sch1Interval_Day}
              handleIntervalChange_Day={handleSetSch1IntervalChange_Day}
              sch1Interval_Hour={sch1Interval_Hour}
              handleIntervalChange_Hour={handleSetSch1IntervalChange_Hour}
              sch1Interval_Minute={sch1Interval_Minute}
              handleIntervalChange_Minute={handleSetSch1IntervalChange_Minute}
              handleRepeatChange={handleSetSch1RepeatChange}
              repeat={sch1Repeat}
              preAcDate={preAcChecked ? preAcDate : 0}
              startTimeDate={startTimeDate}
              endTimeDate={endTimeDate}
              handleSch1NextRepValidation={onSch1NextRepValidation}
              mode={mode}
              reps={calculateReps}
              viewSchedulerMode={viewSchedulerMode}
              Sche1RepDeletionIsValid={Sche1RepDeletionIsValid}
              previewRepetitionAlertObj={previewRepetitionAlertObj}
              hasPropsTouched={hasPropsTouchedInSch1}
            ></SchedualIntervalTime>
          </ErrorBoundary>
        </ListItem>
        <Divider className={classes.invisibleDivider} />
        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <SchedualDate
              onSwitchChange={handleSchDateSwitchChange}
              switchChecked={schDateSwitch}
              mode={mode}
              reps={calculateReps}
              schedule2TempDate={schedule2TempDate}
              onSchedule2TempDateChange={handleSchedule2TempDateChange}
              onAddSchedule2Date={handleAddSchedule2Date}
              handleDeleteRep={onDeleteSche2Rep}
              viewSchedulerMode={viewSchedulerMode}
              setDoesSch2RepsOverlap={setDoesSch2RepsOverlap}
              previewRepetitionAlertObj={previewRepetitionAlertObj}
            />
          </ErrorBoundary>
        </ListItem>

        <div className="divider divider-margin">
          <span className="divider-line"></span>
          <span className="divider-content">version metadata</span>
          <span className="divider-line"></span>
        </div>

        <ListItem className={classes.listItem}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              myErrorHandler(error, info);
              setBoundaryErrors(true);
            }}
          >
            <MetaData
              mdMinBundle={mdMinBundle}
              onMDMinBundleChange={handleMDMinBundleChange}
              mdMaxBundle={mdMaxBundle}
              onMDMaxBundleChange={handleMDMaxBundleChange}
              mdConfig={mdConfig}
              onMDConfigChange={handleMDConfigChange}
              versionMetadata={versionMetadata}
              onVersionMetadaAdd={handleAddMetaData}
              onVersionMetadaDelete={handleVersionMetadaDelete}
              onVersionMetadaedit={handleMetadataEdit}
            />
          </ErrorBoundary>
        </ListItem>
        <ListItem>
          <Button
            onClick={handleFormSubmit}
            color="primary"
            variant="contained"
            disabled={onSubmitDisable()}
            style={{ margin: "3rem 1rem 2rem", textAlign: "center" }}
          >
            {`${mode} Event`}
          </Button>
        </ListItem>
      </List>
      {waitingForReq && (
        <CircularProgress color="primary" className="button-progress-sticky" />
      )}
    </Fragment>
  );
}

export default EventForm;
