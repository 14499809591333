import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Tooltip from "@material-ui/core/Tooltip";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import CodeIcon from "@material-ui/icons/Code";

import ConfirmDialog from "./common/confirmDialog";
import JsonEditor from "./common/jsonEditor";

const useStyles = makeStyles((theme) => ({
  switch: {
    alignSelf: "center",
  },
  gridRoot: {
    flexGrow: 1,
    // backgroundColor: "red",
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  metaDataBox: {
    margin: "2rem 0rem",
    border: "1px solid rgba(211,211,211, 0.7)",
    width: "96%",
    borderRadius: "5px",
    padding: "1rem",
  },
  addBtn: {
    margin: "1.5rem 1rem 0",
  },
  iconSpacing: {
    margin: theme.spacing(1),
    padding: "0.25rem",
    marginBottom: "1.5rem",
  },
  editIcon: {
    // margin: theme.spacing(1),
    padding: "0.25rem",
    margin: "1.3rem 0.25rem",
    cursor: "pointer",
    border: "1.5px solid rgba(63,81,181,255)",
    borderRadius: "50%",
  },
  deleteIcon: {
    // margin: theme.spacing(1),
    padding: "0.25rem",
    margin: "1.3rem 0.25rem",
    cursor: "pointer",
    border: "1.5px solid #f50057",
    borderRadius: "50%",
  },
  dialogMargin: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexShrink: 0,
    // flexBasis: "66.33%",
  },
  jsonIcon: {
    marginLeft: "-30px",
    marginTop: "10px",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function MetaData({
  mdMinBundle,
  mdMaxBundle,
  mdConfig,
  onMDMinBundleChange,
  onMDMaxBundleChange,
  onMDConfigChange,

  versionMetadata,
  onVersionMetadaAdd,
  onVersionMetadaedit,
  onVersionMetadaDelete,
}) {
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openJsonEditor, setOpenJsonEditor] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [jsonIsValidAdd, setJsonIsValidAdd] = useState(true);
  const [jsonIsValidEdit, setJsonIsValidEdit] = useState(true);
  const [jsonEditorLocalValue, setJsonEditorLocalValue] = useState("");
  const [jsonEditorLabel, setJsonEditorLabel] = useState("");
  const [isMaxGraterThanMinAdd, setIsMaxGraterThanMinAdd] = useState(true);
  const [isMaxGraterThanMinEdit, setIsMaxGraterThanMinEdit] = useState(true);
  const [cachedId, setCachedId] = useState("");

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleVersionMetadaDelete = (id) => {
    setOpenDeleteDialog(true);
    setCachedId(id);
  };

  const handleDeleteConfirm = () => {
    onVersionMetadaDelete(cachedId);
    setOpenDeleteDialog(false);
  };

  const handleAccordionChange = (panel, md) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setJsonEditorLocalValue(md.mdConfig);
      setCachedId(md.id);
    }
  };

  const handleViewJsonClick = (label, value) => {
    setOpenJsonEditor(true);
    setJsonEditorLabel(label);
    setJsonEditorLocalValue(value);
  };

  const handleJsonEditorClose = () => {
    setOpenJsonEditor(false);
  };

  const handleJsonEditorCofirmClicked = () => {
    if (jsonEditorLabel.toLowerCase().includes("add")) {
      onMDConfigChange(jsonEditorLocalValue);
    } else {
      onVersionMetadaedit(cachedId, "mdConfig", jsonEditorLocalValue);
    }
  };

  const handleAddMetadata = () => {
    if (!jsonEditorLocalValue || jsonIsValidAdd) {
      handleViewJsonClick("Add Config", mdConfig);
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {openJsonEditor && (
            <JsonEditor
              label={jsonEditorLabel}
              open={openJsonEditor}
              onClose={handleJsonEditorClose}
              onJsonEditorCofirmClicked={handleJsonEditorCofirmClicked}
              value={jsonEditorLocalValue || `{ "key": "value" }`}
              handleChange={(obj) => {
                setJsonEditorLocalValue(obj);
                // props.onChange(obj);
              }}
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.gridRoot}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <label htmlFor="minBundleVersion" className="date-picker-label">
              Min Bundle Version *
            </label>
            <InputBase
              fullWidth
              type="number"
              id="minBundleVersion"
              value={mdMinBundle}
              className="input-base"
              onChange={(e) => {
                onMDMinBundleChange(e);
                if (e.target.value) {
                  if (parseInt(e.target.value) <= parseInt(mdMaxBundle)) {
                    setIsMaxGraterThanMinAdd(true);
                  } else {
                    setIsMaxGraterThanMinAdd(false);
                  }
                }
              }}
            />
            {(parseInt(mdMinBundle) < 0 || mdMinBundle.length === 0) && (
              <div className="error-text">
                Please enter a valid bundle number
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <label htmlFor="maxBundleVersion" className="date-picker-label">
              Max Bundle Version *
            </label>
            <InputBase
              fullWidth
              type="number"
              id="maxBundleVersion"
              value={mdMaxBundle}
              className="input-base"
              onChange={(e) => {
                onMDMaxBundleChange(e);
                if (e.target.value) {
                  if (parseInt(e.target.value) >= parseInt(mdMinBundle)) {
                    setIsMaxGraterThanMinAdd(true);
                  } else {
                    setIsMaxGraterThanMinAdd(false);
                  }
                }
              }}
            />
            {!isMaxGraterThanMinAdd && (
              <div className="error-text">
                Max-Bundle should be grater than Min-Bundle
              </div>
            )}
            {mdMaxBundle.length === 0 && (
              <div className="error-text">
                Please enter a valid bundle number
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <label htmlFor="config" className="date-picker-label">
              Config *
            </label>
            <InputBase
              fullWidth
              id="config"
              value={mdConfig}
              className="input-base"
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Show in json editor">
                    <IconButton color="primary" onClick={handleAddMetadata}>
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              onChange={(e) => {
                if (e.target.value) {
                  try {
                    JSON.parse(e.target.value);
                    setJsonIsValidAdd(true);
                  } catch (error) {
                    setJsonIsValidAdd(false);
                  }
                } else {
                  setJsonIsValidAdd(true);
                }
                setJsonEditorLocalValue(e.target.value);
                onMDConfigChange(e.target.value);
              }}
            />
            {!jsonIsValidAdd && (
              <div className="error-text">Json is not valid</div>
            )}
          </Grid>
        </Grid>
        <Button
          className={classes.addBtn}
          variant="contained"
          // color="primary"
          onClick={onVersionMetadaAdd}
          disabled={
            !jsonIsValidAdd ||
            !isMaxGraterThanMinAdd ||
            mdMinBundle.length === 0 ||
            mdMaxBundle.length === 0 ||
            parseInt(mdMinBundle) < 0 ||
            parseInt(mdMaxBundle) < 0 ||
            mdConfig.length === 0
          }
        >
          Add
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {versionMetadata.map((md) => {
              return (
                <div
                  key={md.id}
                  style={{
                    width: "100%",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  <div>
                    <Accordion
                      expanded={expanded === "panel" + md.id}
                      onChange={handleAccordionChange("panel" + md.id, md)}
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        marginTop: "1rem",
                        width: "auto",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <Tooltip title="Expand to Edit">
                            <ExpandMoreIcon />
                          </Tooltip>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="clickable"
                      >
                        <InputBase
                          aria-readonly={true}
                          fullWidth
                          value={`"${md.mdMinBundle}-${md.mdMaxBundle}":${md.mdConfig}`}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <label
                              htmlFor="minBundle"
                              className="date-picker-label"
                            >
                              Min Bundle
                            </label>
                            <InputBase
                              style={{ width: "100%" }}
                              fullWidth
                              id="minBundle"
                              value={md.mdMinBundle}
                              type="number"
                              className="input-base"
                              onChange={(e) => {
                                onVersionMetadaedit(
                                  md.id,
                                  "mdMinBundle",
                                  e.target.value
                                );
                                if (e.target.value) {
                                  if (
                                    parseInt(e.target.value) <=
                                    parseInt(md.mdMaxBundle)
                                  ) {
                                    setIsMaxGraterThanMinEdit(true);
                                  } else {
                                    setIsMaxGraterThanMinEdit(false);
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <label
                              htmlFor="maxBundle"
                              className="date-picker-label"
                            >
                              Max Bundle
                            </label>
                            <InputBase
                              fullWidth
                              id="maxBundle"
                              type="number"
                              value={md.mdMaxBundle}
                              className="input-base"
                              onChange={(e) => {
                                onVersionMetadaedit(
                                  md.id,
                                  "mdMaxBundle",
                                  e.target.value
                                );
                                if (e.target.value) {
                                  if (
                                    parseInt(e.target.value) >=
                                    parseInt(md.mdMinBundle)
                                  ) {
                                    setIsMaxGraterThanMinEdit(true);
                                  } else {
                                    setIsMaxGraterThanMinEdit(false);
                                  }
                                }
                              }}
                            />
                            {!isMaxGraterThanMinEdit && (
                              <div className="error-text">
                                Max-Bundle should be grater than Min-Bundle
                              </div>
                            )}
                            {/* {md.mdMaxBundle.length === 0 && (<div className="error-text">select a valid bundle number</div>)} */}
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <label
                              htmlFor="mdConfig"
                              className="date-picker-label"
                            >
                              Config
                            </label>
                            <InputBase
                              fullWidth
                              label="Config"
                              id="mdConfig"
                              value={md.mdConfig}
                              className="input-base"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Tooltip title="Show in json editor">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        if (
                                          !jsonEditorLocalValue ||
                                          jsonIsValidEdit
                                        ) {
                                          handleViewJsonClick(
                                            "Edit Config",
                                            md.mdConfig
                                          );
                                        }
                                      }}
                                    >
                                      <CodeIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              }
                              onChange={(e) => {
                                onVersionMetadaedit(
                                  md.id,
                                  "mdConfig",
                                  e.target.value
                                );
                                if (e.target.value) {
                                  try {
                                    JSON.parse(e.target.value);
                                    setJsonIsValidEdit(true);
                                  } catch (error) {
                                    setJsonIsValidEdit(false);
                                  }
                                } else {
                                  setJsonIsValidEdit(true);
                                }
                                setJsonEditorLocalValue(e.target.value);
                              }}
                            />
                            {!jsonIsValidEdit && (
                              <div className="error-text">
                                Json is not valid
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          onClick={() => handleVersionMetadaDelete(md.id)}
                          variant="contained"
                        >
                          Delete
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  </div>

                  {openDeleteDialog && (
                    <ConfirmDialog
                      open={openDeleteDialog}
                      onClose={handleDeleteClose}
                      onConfirm={handleDeleteConfirm}
                      itemName="metadata"
                    />
                  )}
                </div>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
