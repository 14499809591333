import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { toasty, error } from "../../services/toastNotification";

export default function CopyDialog({ onClose, open, value }) {
  const handleCopyIconClicked = async () => {
    try {
      await navigator.clipboard.writeText(value);
      toasty("Copied!");
    } catch (err) {
      error("Failed to copy!");
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "left" }}
      >
        <DialogTitle id="alert-dialog-title">Repetition UUID</DialogTitle>
        <DialogContent>
          {value === "" && (
            <DialogContentText id="alert-dialog-description">
              No value provided! Submit the changes to see the uuid.
            </DialogContentText>
          )}
          <TextField
            value={value}
            readOnly
            margin="normal"
            fullWidth
            name="UUID"
            label="UUID"
            type="text"
            id="UUID"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopyIconClicked}>
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
