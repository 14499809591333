export function convertToEpochTime(date) {
  //Convert to readable format for Unity - convert ms to s
  let validDate = `${date}`;
  while (validDate.length > 10) {
    validDate = validDate.slice(0, -1);
  }
  validDate = parseInt(validDate);
  return validDate;
}

export function formatDate(date) {
  // Conver to ms
  let validDate = `${date}`;
  if (!validDate) {
    validDate = new Date().getTime();
  }
  while (validDate.length < 13) {
    validDate += "0";
  }
  validDate = parseInt(validDate);
  // return new Date(validDate);
  return validDate;
}

/* ---------------------------------------------------
addDays - addHours - addMinutes - timeDiffCalculation
functions receives date in ms format.
As well as Flatpicker library.
------------------------------------------------------ */

export function addDays(date, days) {
  let result = new Date(date);
  return result.setDate(result.getDate() + parseInt(days));
}

export function addHours(date, hours) {
  let result = new Date(date);
  return result.setHours(result.getHours() + parseInt(hours));
}

export function addMinutes(date, minutes) {
  let result = new Date(date);
  return result.setMinutes(result.getMinutes() + parseInt(minutes));
}

export function timeDiffCalculation(dateFuture, dateNow) {
  dateFuture = dateFuture - new Date(dateFuture).getTimezoneOffset() * 60000;
  dateNow = dateNow - new Date(dateNow).getTimezoneOffset() * 60000;

  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = {};

  difference.days = days;

  difference.hours = hours;

  difference.minutes = minutes;

  return difference;
}

export function ConvertUTCToHumanReadableDate(date) {
  return new Date(formatDate(date)).toLocaleString();
}

export function Contains(range1, range2) {
  // this func checks if range2 contains rage1
  let range1_Start =
    range1?.startPreActiveTime === 0
      ? range1?.startTime
      : range1?.startPreActiveTime;
  let range2_Start =
    range2?.startPreActiveTime === 0
      ? range2?.startTime
      : range2?.startPreActiveTime;
  let range1_End = range1?.endTime;
  let range2_End = range2?.endTime;

  if (range1_Start >= range2_Start && range1_End <= range2_End) {
    return true;
  }

  return false;
}

export function Overlap(reps) {
  for (let i = 0; i < reps.length; i++) {
    let nextRep_Satrt =
      reps[i + 1]?.startPreActiveTime === 0
        ? reps[i + 1]?.startTime
        : reps[i + 1]?.startPreActiveTime;
    let cuurentRep_End = reps[i].endTime;
    if (cuurentRep_End > nextRep_Satrt) {
      return true;
    }
  }
  return false;
}

export function calculateRepHasStartedMode(reps) {
  const currentDate = convertToEpochTime(new Date().getTime());
  for (let i = 0; i < reps.length; i++) {
    if (!reps[i].customFields) {
      reps[i].customFields = {};
      reps[i].customFields.hasStarted = false;
    }
    let repStart =
      reps[i]["startPreActiveTime"] === 0
        ? reps[i]["startTime"]
        : reps[i]["startPreActiveTime"];
    reps[i].customFields.hasStarted = currentDate > repStart ? true : false;
  }
  return reps;
}
