import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import { JsonEditor as Editor } from "jsoneditor-react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import "jsoneditor-react/es/editor.min.css";

const JsonEditor = ({
  open,
  onClose,
  value,
  handleChange,
  label,
  onJsonEditorCofirmClicked,
}) => {
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="json-viewer-label"
        aria-describedby="json-viewer-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction="up" mountOnEnter unmountOnExit>
          <div className={classes.paper}>
            <div className={classes.btnSection}>
              <h3 className={classes.labelText}>{label}</h3>
              <Button
                variant="contained"
                color="primary"
                endIcon={<AssignmentTurnedInIcon />}
                onClick={() => {
                  onJsonEditorCofirmClicked();
                  onClose();
                }}
              >
                confirm
              </Button>
            </div>
            <div className={classes.editorSection}>
              <Editor
                value={JSON.parse(value)}
                onChange={(obj) => {
                  handleChange(JSON.stringify(obj));
                }}
              />
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  );
};

export default JsonEditor;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: "300px",
    width: "80%",
    height: "90%",
    minHeight: "300px",
    overflow: "auto",
  },
  btnSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
    alignItems: "center",
  },
  editorSection: {
    overflow: "auto",
    height: "100%",
  },
  labelText: {
    margin: "0px",
  },
}));
